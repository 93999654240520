define("egift-frontend/models/confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CONFIRMATION_TYPES = void 0;
  var CONFIRMATION_TYPES = {
    GROUP_CONTRIBUTION: 'GROUP_CONTRIBUTION',
    GROUP_GIFT_SALE: 'GROUP_GIFT_SALE',
    RELOAD: 'RELOAD',
    ORDER: 'ORDER'
  };
  _exports.CONFIRMATION_TYPES = CONFIRMATION_TYPES;
});