define("egift-frontend/instance-initializers/sentry", ["exports", "@sentry/browser", "@sentry/integrations", "egift-frontend/config/environment", "egift-frontend/utils/environment"], function (_exports, Sentry, Integrations, _environment, _environment2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // TransitionAborted happens during normal route redirects, and it shouldn't actually be reported to Sentry
  // as an exception: https://github.com/emberjs/ember.js/issues/12505
  var IGNORED_EXCEPTION_TYPES = ['TransitionAborted'];
  function getShaFromVersion(version) {
    return version && version.split('+')[1];
  }
  function initialize() {
    var environment = (0, _environment2.getEnvironmentName)();
    if (!environment || environment === 'development') {
      return;
    }
    var modulePrefix = _environment.default.modulePrefix,
      version = _environment.default.APP.version;
    Sentry.init({
      dsn: 'https://637c34eec0bc4b2195e84b9cdb95120f@sentry.io/1294283',
      integrations: [new Integrations.Ember(), new Sentry.Integrations.InboundFilters({
        ignoreErrors: IGNORED_EXCEPTION_TYPES,
        whitelistUrls: ['/gift/assets/']
      })],
      environment: environment,
      release: "".concat(modulePrefix, "@").concat(getShaFromVersion(version))
    });
  }
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});