define("egift-frontend/templates/components/money-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xGUm0qpe",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"input\"],[24,0,\"text-input text-input--fill\"],[24,\"pattern\",\"*d*\"],[16,2,[30,[36,0],[[32,0,[\"formattedValue\"]]],null]],[17,1],[4,[38,1],[\"change\",[32,0,[\"onChange\"]]],null],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"readonly\",\"on\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/money-input.hbs"
    }
  });
  _exports.default = _default;
});