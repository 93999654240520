define("egift-frontend/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9i5vC9mU",
    "block": "{\"symbols\":[],\"statements\":[[8,\"head-layout\",[],[[],[]],null],[2,\"\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"],[8,\"banner-message\",[],[[],[]],null],[2,\"\\n\"],[10,\"market-context-manager\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/application.hbs"
    }
  });
  _exports.default = _default;
});