define("egift-frontend/templates/components/reload-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wgKofeR3",
    "block": "{\"symbols\":[\"@orderConfiguration\",\"@containerClass\",\"@giftInfo\",\"@prefilledGiftCard\",\"@hideCardInfo\"],\"statements\":[[6,[37,0],[[32,1,[\"globalPromotion\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"global-promotion-banner\",[],[[\"@promotion\"],[[32,1,[\"globalPromotion\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[15,0,[32,2]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-title\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"reload.description\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"reload-form\"],[12],[2,\"\\n    \"],[8,\"check-balance-form\",[[16,0,[32,2]]],[[\"@giftInfo\",\"@buttonText\",\"@showBalanceButton\",\"@beforeSubmitValidation\",\"@moveToRoute\",\"@prefilledGiftCard\",\"@hideCardInfo\"],[[32,3],[30,[36,1],[\"reload.submit\"],null],true,[32,0,[\"userHasSubmitted\"]],[32,0,[\"moveToRoute\"]],[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"reload-info-form\"],[12],[2,\"\\n        \"],[8,\"amount-and-promo-input\",[],[[\"@giftInfo\",\"@orderConfiguration\",\"@prefilledPromotionCode\",\"@showPromoInput\",\"@hasUserSubmitted\",\"@label\"],[[32,3],[32,1],[32,0,[\"promo_code\"]],false,[32,0,[\"hasUserSubmitted\"]],[30,[36,1],[\"reload.subheader\"],null]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"t\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/reload-form.hbs"
    }
  });
  _exports.default = _default;
});