define("egift-frontend/templates/components/recaptcha", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EfsDOHbc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[16,1,[32,0,[\"captchaElementId\"]]],[24,0,\"g-recaptcha\"],[4,[38,0],[[32,0,[\"loadCaptchaScript\"]]],null],[12],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,[[32,0,[\"execute\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/recaptcha.hbs"
    }
  });
  _exports.default = _default;
});