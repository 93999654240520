define("egift-frontend/templates/components/copy-to-clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7OM/IKgR",
    "block": "{\"symbols\":[\"&attrs\",\"@value\"],\"statements\":[[11,\"div\"],[24,0,\"copy-to-clipboard\"],[17,1],[4,[38,0],[\"click\",[32,0,[\"copyToClipboard\"]]],null],[12],[2,\"\\n  \"],[8,\"input\",[[16,1,[32,0,[\"inputElementId\"]]],[24,0,\"copy-to-clipboard__input\"],[24,\"readonly\",\"\"]],[[\"@value\"],[[32,2]]],null],[2,\"\\n  \"],[10,\"button\"],[15,0,[31,[\"type-paragraph-normal copy-to-clipboard__copy-button\\n            \",[30,[36,1],[[32,0,[\"isInCopyCooldown\"]],\"copy-to-clipboard__copy-button--active\"],null]]]],[14,4,\"button\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,0,[\"isInCopyCooldown\"]],[30,[36,2],[\"clipboard.linkCopied\"],null],[30,[36,2],[\"clipboard.copyLink\"],null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\",\"t\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/copy-to-clipboard.hbs"
    }
  });
  _exports.default = _default;
});