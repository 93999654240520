define("egift-frontend/templates/components/group-gift-contributor-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aL5nubsa",
    "block": "{\"symbols\":[\"contribution\",\"@groupContributionDetails\"],\"statements\":[[10,\"ul\"],[14,0,\"group-gift-contributors__contributor-list\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"group-gift-contributors__contributor-list-row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"group-gift-contributors__contributor-list-icon\"],[12],[2,\"\\n        \"],[1,[32,1,[\"contributor\",\"initial\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"customMessage\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"group-gift-contributors__contributor-list-name\"],[12],[1,[32,1,[\"contributor\",\"name\"]]],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"group-gift-contributors__contributor-list-message\"],[12],[1,[32,1,[\"customMessage\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"group-gift-contributors__contributor-list-name-full\"],[12],[1,[32,1,[\"contributor\",\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/group-gift-contributor-list.hbs"
    }
  });
  _exports.default = _default;
});