define("egift-frontend/utils/query-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toQueryString = toQueryString;
  function toQueryString(hash) {
    var queryString = Object.keys(hash).map(function (key) {
      return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(hash[key]));
    }).join('&');
    return "?".concat(queryString);
  }
});