define("egift-frontend/templates/components/gift-info-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6A8BFutD",
    "block": "{\"symbols\":[\"@showHeroText\",\"@selectedTheme\",\"@themes\",\"@onChangeTheme\",\"@merchantName\"],\"statements\":[[10,\"div\"],[14,0,\"gift-info-header\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"gift-info-header__hero\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"hero-text\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"giftInfoHeader.heroText\"],null]],[2,\"\\n        \"],[10,\"div\"],[14,0,\"hero-text__secondary\"],[12],[1,[30,[36,0],[\"giftInfoHeader.heroSecondary.voucher\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"gift-info-header__background\"],[15,5,[30,[36,1],[[32,2],[30,[36,2],[[32,2,[\"backgroundColor\"]]],null],\"background-color: rgba(0, 0, 0, 0.05)\"],null]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"gift-info-header__carousel\"],[12],[2,\"\\n    \"],[8,\"card-carousel\",[],[[\"@themes\",\"@selected\",\"@onChange\",\"@merchantName\"],[[32,3],[32,2],[32,4],[32,5]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"background-color\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/gift-info-header.hbs"
    }
  });
  _exports.default = _default;
});