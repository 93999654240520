define("egift-frontend/templates/components/pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6qQnVpI5",
    "block": "{\"symbols\":[\"@isActive\",\"&attrs\",\"@onClick\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"type-paragraph-large pill \",[30,[36,0],[[32,1],\"pill--is-active\"],null]]]],[17,2],[12],[2,\"\\n  \"],[8,\"input\",[[16,1,[32,0,[\"inputElementId\"]]],[24,0,\"pill__input\"],[24,4,\"checkbox\"],[4,[38,1],[\"click\",[32,3]],null]],[[\"@checked\"],[[32,1]]],null],[2,\"\\n  \"],[10,\"label\"],[15,\"for\",[32,0,[\"inputElementId\"]]],[14,0,\"pill__label\"],[12],[2,\"\\n    \"],[18,4,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/pill.hbs"
    }
  });
  _exports.default = _default;
});