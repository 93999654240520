define("egift-frontend/templates/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aKGdhk+X",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"loading-spinner__container\"],[17,1],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"loading-spinner__icon\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/loading-spinner.hbs"
    }
  });
  _exports.default = _default;
});