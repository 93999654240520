define("egift-frontend/helpers/format-phone", ["exports", "libphonenumber-js", "@sentry/browser"], function (_exports, _libphonenumberJs, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatPhone = formatPhone;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function formatPhone(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
      phoneNumber = _ref2[0];
    var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      country = _ref3.country;
    (false && !(country) && Ember.assert('format-phone country parameter is required', country));
    var parsedPhoneNumber;
    try {
      parsedPhoneNumber = (0, _libphonenumberJs.parsePhoneNumber)(phoneNumber, country);
    } catch (error) {
      if (error instanceof _libphonenumberJs.ParseError) {
        // This shouldn't happen if the phone number comes from the server,
        // so log it and fall back to displaying an unformatted number.
        Sentry.captureException(error);
        return phoneNumber;
      }
      throw error;
    }

    // Show the country code if the phone number does not belong to the same country as the merchant
    return parsedPhoneNumber.country === country ? parsedPhoneNumber.formatNational() : parsedPhoneNumber.formatInternational();
  }
  var _default = Ember.Helper.helper(formatPhone);
  _exports.default = _default;
});