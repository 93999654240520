define("egift-frontend/templates/components/date-time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HQ9T5IOP",
    "block": "{\"symbols\":[\"hasError\",\"@shouldEnableValidations\",\"@validationMessage\",\"&attrs\",\"@placeholder\",\"@displayDate\"],\"statements\":[[8,\"validation-tooltip\",[],[[\"@enabled\",\"@message\"],[[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"market-dropdown\"],[17,4],[16,0,[31,[\"text-input--fill \",[30,[36,0],[[32,1],\"text-input--error\"],null]]]],[24,\"interaction\",\"persistent\"],[24,\"popover-placement\",\"bottom-start\"],[4,[38,1],[\"click\",[32,0,[\"setFocus\"]]],null],[12],[2,\"\\n    \"],[10,\"market-input-text\"],[14,\"slot\",\"trigger\"],[15,2,[32,0,[\"formattedDate\"]]],[15,\"inValid\",[32,1]],[12],[2,\"\\n      \"],[10,\"label\"],[12],[1,[32,5]],[13],[2,\"\\n\\n      \"],[10,\"market-accessory\"],[14,\"size\",\"small\"],[14,\"variant\",\"icon\"],[14,\"slot\",\"trailing-accessory\"],[12],[2,\"\\n        \"],[8,\"sq-icon16-ui-chevron-down\",[[24,0,\"date-time-picker__dropdown-icon\"]],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"market-popover\"],[14,\"slot\",\"popover\"],[12],[2,\"\\n      \"],[11,\"market-date-picker\"],[24,\"selection-type\",\"single\"],[24,\"timeframe\",\"future\"],[16,\"displayed-date\",[32,6]],[16,\"selected-start-date\",[32,6]],[16,\"isDateDisabled\",[32,0,[\"isDateDisabled\"]]],[24,\"with-inputs\",\"date-and-time\"],[4,[38,1],[\"marketDateRangeChanged\",[32,0,[\"updateDate\"]]],null],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/date-time-picker.hbs"
    }
  });
  _exports.default = _default;
});