define("egift-frontend/templates/components/text-input-character-counter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JsCIZmqo",
    "block": "{\"symbols\":[\"&attrs\",\"@class\",\"@textValue\",\"@fieldName\",\"@validator\"],\"statements\":[[6,[37,1],[[32,3,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[17,1],[16,0,[32,2]],[12],[1,[32,3,[\"length\"]]],[2,\"/\"],[1,[30,[36,0],[[30,[36,0],[[30,[36,0],[[30,[36,0],[[32,5],\"validations\"],null],\"attrs\"],null],[32,4]],null],\"options.length.max\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"get\",\"if\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/text-input-character-counter.hbs"
    }
  });
  _exports.default = _default;
});