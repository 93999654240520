define("egift-frontend/events/payment/tealium-event-catalog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.giftCardReloadEvent = _exports.eGCPurchaseEvent = void 0;
  var eGCPurchaseEvent = 'egiftcard_purchase'; // this event is used by GTM so shouldn't be changed
  _exports.eGCPurchaseEvent = eGCPurchaseEvent;
  var giftCardReloadEvent = 'giftcard_reload'; // this event is used by GTM so shouldn't be changed
  _exports.giftCardReloadEvent = giftCardReloadEvent;
});