define("egift-frontend/templates/components/email-link-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ICsWLbiq",
    "block": "{\"symbols\":[\"@emailAddress\",\"@buttonText\"],\"statements\":[[10,\"form\"],[15,\"onsubmit\",[30,[36,0],[[32,0,[\"submit\"]]],null]],[14,\"novalidate\",\"\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field-group\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field-group__row\"],[12],[2,\"\\n      \"],[8,\"input\",[[24,0,\"text-input text-input--fill\"],[24,\"placeholder\",\"email@example.com\"],[16,2,[32,0,[\"emailAddress\"]]],[4,[38,1],[\"input\",[32,0,[\"onInputChange\"]]],null]],[[\"@onChange\",\"@emailAddress\"],[[32,0,[\"updateEmailAddress\"]],[32,1]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field-group__row\"],[12],[2,\"\\n      \"],[10,\"button\"],[15,0,[31,[\"button button--primary button--fill \",[30,[36,2],[[32,0,[\"isLoading\"]],\"button--loading\"],null]]]],[15,\"disabled\",[32,0,[\"isLoading\"]]],[14,4,\"submit\"],[12],[2,\"\\n        \"],[10,\"i\"],[12],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"button__text\"],[12],[1,[32,2]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"prevent-default\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/email-link-form.hbs"
    }
  });
  _exports.default = _default;
});