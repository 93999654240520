define("egift-frontend/utils/camelize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deepCamelizeKeys = deepCamelizeKeys;
  _exports.deepDecamelizeKeys = deepDecamelizeKeys;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function isNonNullObject(value) {
    return Boolean(value && _typeof(value) === 'object');
  }
  function isString(value) {
    return Boolean(typeof value === 'string' || value instanceof String);
  }

  // It'd be nice to use Object.fromEntries here, but that breaks IE and Edge.
  // Babel isn't transpiling it properly, and I don't know why...
  function deepTransformKeys(sourceObj, transform) {
    if (isString(sourceObj)) {
      return sourceObj;
    }
    if (Array.isArray(sourceObj)) {
      return sourceObj.map(function (el) {
        return deepTransformKeys(el, transform);
      });
    }
    var transformedObj = {};
    Object.keys(sourceObj).forEach(function (key) {
      var value = sourceObj[key];
      transformedObj[transform(key)] = isNonNullObject(value) ? deepTransformKeys(value, transform) : value;
    });
    return transformedObj;
  }
  function deepCamelizeKeys(snakeCaseObj) {
    return deepTransformKeys(snakeCaseObj, Ember.String.camelize);
  }
  function deepDecamelizeKeys(camelCaseObj) {
    return deepTransformKeys(camelCaseObj, Ember.String.decamelize);
  }
});