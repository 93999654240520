define("egift-frontend/templates/components/modal/recipient-emails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hYBmLuk6",
    "block": "{\"symbols\":[\"recipient\",\"@onDialogDismissed\",\"@recipients\"],\"statements\":[[11,\"template\"],[4,[38,0],null,null],[12],[2,\"\\n  \"],[11,\"market-modal-partial\"],[4,[38,1],[\"marketDialogDismissed\",[32,2]],null],[12],[2,\"\\n    \"],[10,\"market-header\"],[14,0,\"modal__header\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,\"slot\",\"wayfinding\"],[14,0,\"modal__header-title\"],[12],[1,[30,[36,2],[\"modal.recipientEmails.eGiftRecipients\"],null]],[13],[2,\"\\n      \"],[10,\"div\"],[14,\"slot\",\"actions\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"main\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"modal__body\"],[12],[2,\"\\n        \"],[10,\"ol\"],[14,0,\"modal__ordered-list\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"li\"],[12],[1,[32,1,[\"email\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"open-modal\",\"on\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/modal/recipient-emails.hbs"
    }
  });
  _exports.default = _default;
});