define("egift-frontend/templates/components/hero-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FpJNxWQ2",
    "block": "{\"symbols\":[\"&default\",\"&attrs\",\"@iconName\",\"@title\",\"@subtitle\"],\"statements\":[[11,\"div\"],[24,0,\"hero-banner\"],[17,2],[12],[2,\"\\n  \"],[1,[30,[36,0],[[32,3]],[[\"class\"],[\"hero-banner__icon\"]]]],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"hero-banner__title\"],[12],[2,\"\\n    \"],[1,[32,4]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"hero-banner__subtitle\"],[12],[2,\"\\n    \"],[1,[32,5]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"hero-banner__content\"],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"if\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/hero-banner.hbs"
    }
  });
  _exports.default = _default;
});