define("egift-frontend/utils/environment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ENVIRONMENT_NAME = void 0;
  _exports.getEnvironmentName = getEnvironmentName;
  var ENVIRONMENT_NAME = {
    DEVELOPMENT: 'development',
    PRODUCTION: 'production',
    STAGING: 'staging'
  };
  _exports.ENVIRONMENT_NAME = ENVIRONMENT_NAME;
  function getEnvironmentName() {
    var hostname = window.location.hostname;
    if (/squareup\.com/.test(hostname)) {
      return ENVIRONMENT_NAME.PRODUCTION;
    }
    if (/giveandgetlocal\.com/.test(hostname)) {
      return ENVIRONMENT_NAME.PRODUCTION;
    }
    if (/squareupstaging\.com/.test(hostname)) {
      return ENVIRONMENT_NAME.STAGING;
    }
    if (/localhost/.test(hostname)) {
      return ENVIRONMENT_NAME.DEVELOPMENT;
    }
    return '';
  }
});