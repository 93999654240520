define("egift-frontend/utils/google-maps", ["exports", "egift-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createAutocompleteService = createAutocompleteService;
  _exports.createAutocompleteSessionToken = createAutocompleteSessionToken;
  _exports.createPlacesService = createPlacesService;
  _exports.injectPlacesScriptTag = injectPlacesScriptTag;
  function injectPlacesScriptTag(onLoad) {
    var _window$google, _window$google$maps;
    if ((_window$google = window.google) !== null && _window$google !== void 0 && (_window$google$maps = _window$google.maps) !== null && _window$google$maps !== void 0 && _window$google$maps.places) {
      // Don't load twice
      return;
    }
    var scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.src = "https://maps.googleapis.com/maps/api/js?key=".concat(_environment.default.placesApiKey, "&libraries=places");
    if (onLoad) {
      scriptTag.addEventListener('load', onLoad);
    }
    document.body.appendChild(scriptTag);
  }
  function createAutocompleteService() {
    var _window$google2, _window$google2$maps, _window$google2$maps$;
    var AutocompleteService = (_window$google2 = window.google) === null || _window$google2 === void 0 ? void 0 : (_window$google2$maps = _window$google2.maps) === null || _window$google2$maps === void 0 ? void 0 : (_window$google2$maps$ = _window$google2$maps.places) === null || _window$google2$maps$ === void 0 ? void 0 : _window$google2$maps$.AutocompleteService;
    if (!AutocompleteService) {
      throw new Error('Attempted to initialize AutocompleteService before library was loaded');
    }
    return new AutocompleteService();
  }
  function createPlacesService(attributionContainer) {
    var _window$google3, _window$google3$maps, _window$google3$maps$;
    var PlacesService = (_window$google3 = window.google) === null || _window$google3 === void 0 ? void 0 : (_window$google3$maps = _window$google3.maps) === null || _window$google3$maps === void 0 ? void 0 : (_window$google3$maps$ = _window$google3$maps.places) === null || _window$google3$maps$ === void 0 ? void 0 : _window$google3$maps$.PlacesService;
    if (!PlacesService) {
      throw new Error('Attempted to initialize AutocompleteService before library was loaded');
    }
    if (!attributionContainer) {
      throw new Error('Must provide an attributionContainer element');
    }
    return new PlacesService(attributionContainer);
  }
  function createAutocompleteSessionToken() {
    return new window.google.maps.places.AutocompleteSessionToken();
  }
});