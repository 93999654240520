define("egift-frontend/instance-initializers/tracking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getPageViewDescriptionForRoute = getPageViewDescriptionForRoute;
  _exports.initialize = initialize;
  var pageViewDescriptionsForRoutes = {
    index: 'Index',
    discover: 'Discover',
    error: 'Error',
    balance: 'Check Balance: Gift Card Token',
    'merchant.check-balance': 'Check Balance',
    'merchant.confirm-link': 'Link Phone Number: Success',
    'merchant.confirmation': 'Order: Success Confirmation',
    'merchant.link': 'Link Phone Number: Form',
    'merchant.order': 'Order: Gift Info',
    'merchant.payment': 'Order: Payment',
    'merchant.reload': 'Reload: Gift Info',
    'merchant.print': 'Print',
    'merchant.resend': 'Resend',
    'merchant.view': 'View Gift Card',
    'merchant.group': 'View Group Gift Card'
  };
  function getPageViewDescriptionForRoute(routeName) {
    var description = pageViewDescriptionsForRoutes[routeName];
    (false && !(description) && Ember.assert("Couldn't find route '".concat(routeName, "' in description mapping"), description)); // Fall back to using the route name in production
    return description || routeName;
  }
  function initialize(appInstance) {
    var intlService = appInstance.lookup('service:intl');
    var routerService = appInstance.lookup('service:router');
    var trackingService = appInstance.lookup('service:tracking');
    routerService.on('routeDidChange', function (transition) {
      var routeName = transition.to.name;
      if (routeName === 'error') {
        // Don't track error route views
        return;
      }
      trackingService.pageView({
        locale_language: intlService.primaryLocale,
        page_view_description: getPageViewDescriptionForRoute(routeName)
      });
    });
  }
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});