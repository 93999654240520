define("egift-frontend/templates/components/modal/merchant-websites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gIy+2Cal",
    "block": "{\"symbols\":[\"merchantWebsite\",\"@onDialogDismissed\",\"@merchantWebsites\"],\"statements\":[[11,\"template\"],[4,[38,1],null,null],[12],[2,\"\\n  \"],[11,\"market-modal-partial\"],[4,[38,2],[\"marketDialogDismissed\",[32,2]],null],[12],[2,\"\\n    \"],[10,\"market-header\"],[14,0,\"modal__header\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,\"slot\",\"actions\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"main\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"modal__body\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"ways-to-redeem__container hide-from-print-version\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"ways-to-redeem__other-instructions-container\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"ways-to-redeem__header\"],[12],[1,[30,[36,3],[\"waysToRedeem.redeemOnline\"],null]],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"ways-to-redeem__instructions\"],[12],[1,[30,[36,3],[\"waysToRedeem.redeemOnlineInstructions\"],null]],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"redemption-method\",[[24,0,\"hide-from-print-version\"]],[[\"@iconPath\",\"@actionName\",\"@actionFn\",\"@shouldShowNewView\"],[\"computer\",[32,1,[\"domain\"]],[30,[36,0],[[32,0,[\"openMerchantWebsite\"]],[32,1]],null],true]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"open-modal\",\"on\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/modal/merchant-websites.hbs"
    }
  });
  _exports.default = _default;
});