define("egift-frontend/services/market-toasts", ["exports", "@market/ember/services/market-toasts"], function (_exports, _marketToasts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _marketToasts.default;
    }
  });
});