define("egift-frontend/instance-initializers/ember-intl", ["exports", "egift-frontend/utils/locale"], function (_exports, _locale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(appInstance) {
    var intlService = appInstance.lookup('service:intl');
    intlService.set('locale', (0, _locale.chooseLocale)(intlService.locales, {}, ''));
  }
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});