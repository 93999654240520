define("egift-frontend/utils/computed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.equal = equal;
  _exports.notEqual = notEqual;
  _exports.trim = trim;
  function equal(property, value) {
    return Ember.computed(property, function () {
      return this.get(property) === value;
    });
  }
  function notEqual(property, value) {
    return Ember.computed(property, function () {
      return this.get(property) !== value;
    });
  }
  function trim(property) {
    return Ember.computed(property, function () {
      var value = this.get(property);
      return value && value.trim();
    });
  }
});