define("egift-frontend/templates/components/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NAUcPeHH",
    "block": "{\"symbols\":[\"@checked\",\"&attrs\",\"@onChange\",\"@label\"],\"statements\":[[11,\"label\"],[24,0,\"checkbox\"],[24,\"role\",\"checkbox\"],[16,\"aria-checked\",[31,[[32,1]]]],[17,2],[4,[38,3],[\"click\",[30,[36,2],[[30,[36,1],[[32,3],[30,[36,0],[[32,1]],null]],null]],null]],null],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"checkbox__checkbox-container\\n             \",[30,[36,4],[[32,1],\"checkbox__checkbox-container--checked\"],null],\"\\n             \",[30,[36,4],[[32,0,[\"isFocused\"]],\"checkbox__checkbox-container--focused\"],null]]]],[12],[2,\"\\n    \"],[11,\"input\"],[24,0,\"checkbox__input\"],[16,\"checked\",[32,1]],[24,\"readonly\",\"\"],[24,4,\"checkbox\"],[4,[38,3],[\"focusin\",[30,[36,1],[[32,0,[\"setFocused\"]],true],null]],null],[4,[38,3],[\"focusout\",[30,[36,1],[[32,0,[\"setFocused\"]],false],null]],null],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"checkbox__checkmark\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"checkbox__label\"],[12],[2,\"\\n    \"],[1,[32,4]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"not\",\"fn\",\"prevent-default\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/checkbox.hbs"
    }
  });
  _exports.default = _default;
});