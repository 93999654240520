define("egift-frontend/utils/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getCurrentDate = getCurrentDate;
  // A wrapper function to get the current date. This was added to
  // support stubbing dates in acceptance tests. Stubbing the Date
  // object using sinon.useFakeTimers causes tests to hang when
  // running await visit(url).
  function getCurrentDate() {
    return new Date();
  }
});