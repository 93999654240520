define("egift-frontend/utils/localized-urls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SQUARE_DOMAIN = void 0;
  _exports.getESignConsentUrlForCountry = getESignConsentUrlForCountry;
  _exports.getGiveGetLocalTermsUrlForCountry = getGiveGetLocalTermsUrlForCountry;
  _exports.getPrivacyPolicyUrlForCountry = getPrivacyPolicyUrlForCountry;
  var SQUARE_DOMAIN = 'https://squareup.com';
  _exports.SQUARE_DOMAIN = SQUARE_DOMAIN;
  function getPrivacyPolicyUrlForCountry(countryCode) {
    switch (countryCode) {
      case 'AU':
        return "".concat(SQUARE_DOMAIN, "/au/legal/general/privacy");
      case 'CA':
        return "".concat(SQUARE_DOMAIN, "/ca/legal/general/privacy-no-account");
      case 'FR':
        return "".concat(SQUARE_DOMAIN, "/fr/legal/general/privacy-no-account");
      case 'GB':
        return "".concat(SQUARE_DOMAIN, "/gb/legal/general/privacy-no-account");
      case 'IE':
        return "".concat(SQUARE_DOMAIN, "/ie/legal/general/privacy-no-account");
      case 'JP':
        return "".concat(SQUARE_DOMAIN, "/jp/legal/general/privacy");
      case 'ES':
        return "".concat(SQUARE_DOMAIN, "/es/legal/general/privacy-no-account");
      case 'US':
      default:
        return "".concat(SQUARE_DOMAIN, "/legal/general/privacy-no-account");
    }
  }
  function getESignConsentUrlForCountry(countryCode) {
    switch (countryCode) {
      case 'AU':
        return "".concat(SQUARE_DOMAIN, "/au/legal/general/sign");
      case 'CA':
        return "".concat(SQUARE_DOMAIN, "/ca/legal/general/sign");
      case 'FR':
        return "".concat(SQUARE_DOMAIN, "/fr/legal/general/sign");
      case 'GB':
        return "".concat(SQUARE_DOMAIN, "/gb/legal/general/sign");
      case 'IE':
        return "".concat(SQUARE_DOMAIN, "/ie/legal/general/sign");
      case 'JP':
        return "".concat(SQUARE_DOMAIN, "/jp/legal/general/sign");
      case 'ES':
        return "".concat(SQUARE_DOMAIN, "/es/legal/general/sign");
      case 'US':
      default:
        return "".concat(SQUARE_DOMAIN, "/legal/general/sign");
    }
  }
  function getGiveGetLocalTermsUrlForCountry(countryCode) {
    var path = '/legal/general/giftcard';
    switch (countryCode) {
      case 'AU':
        return "".concat(SQUARE_DOMAIN, "/au").concat(path);
      case 'CA':
        return "".concat(SQUARE_DOMAIN, "/ca").concat(path);
      case 'GB':
        return "".concat(SQUARE_DOMAIN, "/gb").concat(path);
      case 'JP':
        return "".concat(SQUARE_DOMAIN, "/jp").concat(path);
      case 'US':
        return "".concat(SQUARE_DOMAIN, "/us").concat(path);
      default:
        return "".concat(SQUARE_DOMAIN).concat(path);
    }
  }
});