define("egift-frontend/templates/components/gift-card-qrcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9rjQwshp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"market-qrcode\"],[15,\"content\",[32,0,[\"qrCodeValue\"]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/gift-card-qrcode.hbs"
    }
  });
  _exports.default = _default;
});