define("egift-frontend/templates/components/discover/merchant-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WJjzfHaO",
    "block": "{\"symbols\":[\"merchant\",\"&attrs\",\"@merchants\"],\"statements\":[[11,\"ul\"],[24,0,\"discover__merchant-list\"],[17,2],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[8,\"discover/merchant-card\",[],[[\"@merchant\"],[[32,1]]],null],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/discover/merchant-list.hbs"
    }
  });
  _exports.default = _default;
});