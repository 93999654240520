define("egift-frontend/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ob9fvM4S",
    "block": "{\"symbols\":[],\"statements\":[[10,\"script\"],[15,\"src\",[32,0,[\"model\",\"martechURL\"]]],[14,4,\"text/javascript\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "egift-frontend/templates/head.hbs"
    }
  });
  _exports.default = _default;
});