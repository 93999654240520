define("egift-frontend/helpers/no-submit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.noSubmit = noSubmit;
  function noSubmit() {
    return function (val, e) {
      if (e.which === 13) {
        e.preventDefault();
      }
    };
  }
  var _default = Ember.Helper.helper(noSubmit);
  _exports.default = _default;
});