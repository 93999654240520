define("egift-frontend/templates/merchant/payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "f4tZ67dv",
    "block": "{\"symbols\":[],\"statements\":[[8,\"payment-container\",[],[[\"@model\",\"@processGiftCardSale\",\"@preSubmitValidation\"],[[32,0,[\"model\"]],[32,0,[\"processGiftCardSale\"]],[32,0,[\"validateReloadEmail\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "egift-frontend/templates/merchant/payment.hbs"
    }
  });
  _exports.default = _default;
});