define("egift-frontend/templates/components/check-balance-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1TIJ+YI3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-title\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"checkBalance.description\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"check-balance-form\",[[24,0,\"container\"]],[[\"@buttonText\",\"@moveToRoute\",\"@showBalanceButton\"],[[30,[36,0],[\"checkBalance.submit\"],null],[32,0,[\"moveToRoute\"]],false]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/check-balance-container.hbs"
    }
  });
  _exports.default = _default;
});