define("egift-frontend/templates/components/global-promotion-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "b6D8loVF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"global-promotion-banner\"],[12],[2,\"\\n  \"],[1,[32,0,[\"bannerText\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/global-promotion-banner.hbs"
    }
  });
  _exports.default = _default;
});