define("egift-frontend/utils/clipboard", ["exports", "@sentry/browser"], function (_exports, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.copyToClipboard = copyToClipboard;
  // This method will copy text within an input or textarea element to
  // the clipboard. It must be triggered by a click action, otherwise
  // browsers will not allow the execCommand to copy for security.
  //
  // Implementation borrowed from https://github.com/zenorocha/clipboard.js.

  function copyToClipboard(element) {
    var isValidElement = element && ['input', 'textarea'].includes(element.tagName.toLowerCase());
    (false && !(isValidElement) && Ember.assert('An input or textarea element must be passed to copy.', isValidElement));
    try {
      element.select();
      // IE requires a selection range, rather than select.
      element.setSelectionRange(0, element.value.length);
      document.execCommand('copy');
      element.blur();
      document.getSelection().removeAllRanges();
    } catch (error) {
      Sentry.captureException(error);
    }
  }
});