define("egift-frontend/templates/components/print-egift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RlWvQuiT",
    "block": "{\"symbols\":[\"@gan\",\"@eGiftThemeUrl\",\"@balance\"],\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"img\"],[15,\"src\",[32,2]],[14,0,\"print-egift__card-image\"],[15,\"alt\",[30,[36,0],[\"printEgift.themeAltText\"],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[15,0,[31,[[30,[36,1],[[32,2],\"print-egift__details\"],null]]]],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"print-egift__money-label\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[32,3]],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"print-egift__redemption\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"shouldShowBarcode\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"print-egift__barcode\"],[12],[2,\"\\n        \"],[8,\"gift-card-barcode\",[],[[\"@gan\"],[[32,1]]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"print-egift__gan\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[[32,1]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"format-money\",\"format-gan\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/print-egift.hbs"
    }
  });
  _exports.default = _default;
});