define("egift-frontend/utils/merchant-websites", ["exports", "egift-frontend/utils/browser"], function (_exports, _browser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getUrlForMerchantWebsite = getUrlForMerchantWebsite;
  _exports.openMerchantWebsite = openMerchantWebsite;
  var UTM_SOURCE_QUERY_PARAM = 'utm_source=gc';
  function getUrlForMerchantWebsite(merchantWebsite) {
    if (Ember.isEmpty(merchantWebsite) || Ember.isEmpty(merchantWebsite.domain)) {
      return null;
    }
    return "https://".concat(merchantWebsite.domain, "?").concat(UTM_SOURCE_QUERY_PARAM);
  }
  function openMerchantWebsite(merchantWebsite) {
    var merchantWebsiteUrl = getUrlForMerchantWebsite(merchantWebsite);
    if (!Ember.isEmpty(merchantWebsiteUrl)) {
      (0, _browser.windowOpen)(merchantWebsiteUrl);
      return merchantWebsiteUrl;
    }
    return null;
  }
});