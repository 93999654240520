define("egift-frontend/templates/balance-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "f9wmycC5",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"error\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"routeError.giftCardNotFound\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/balance-error.hbs"
    }
  });
  _exports.default = _default;
});