define("egift-frontend/templates/merchant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pEhDjII6",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"shouldHidePageHeader\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"header-v2\",[],[[\"@merchantLogoUrl\",\"@shouldShowCheckBalanceLink\",\"@shouldShowReloadLink\",\"@shouldShowBuyLink\",\"@merchantName\"],[[32,0,[\"model\",\"merchantProfile\",\"merchantLogoUrl\"]],[32,0,[\"model\",\"shouldShowCheckBalanceLink\"]],[32,0,[\"model\",\"shouldShowReloadLink\"]],[32,0,[\"model\",\"shouldShowBuyLink\"]],[32,0,[\"model\",\"merchantProfile\",\"merchantName\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"],[8,\"app-footer\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unless\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/merchant.hbs"
    }
  });
  _exports.default = _default;
});