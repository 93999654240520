define("egift-frontend/templates/components/discover/share-on-facebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nC5xabdl",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"a\"],[16,6,[32,0,[\"url\"]]],[24,\"target\",\"_blank\"],[17,1],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"openSharingWindow\"]]],null]],null],[12],[2,\"\\n  \"],[1,[30,[36,2],[\"icon-facebook-light\"],null]],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"prevent-default\",\"on\",\"inline-svg\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/discover/share-on-facebook.hbs"
    }
  });
  _exports.default = _default;
});