define("egift-frontend/templates/components/discover/merchant-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "P90rwDSc",
    "block": "{\"symbols\":[\"@merchant\"],\"statements\":[[10,\"a\"],[15,0,[31,[\"discover__merchant-card__container \",[32,0,[\"cssClass\"]]]]],[15,6,[32,0,[\"orderPageUrl\"]]],[14,\"target\",\"_blank\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"discover__merchant-card__card\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"cardImageUrl\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[14,0,\"discover__merchant-card__image\"],[15,\"src\",[32,0,[\"cardImageUrl\"]]],[15,\"alt\",[30,[36,0],[\"header.logoAltText\"],[[\"merchantName\"],[[32,1,[\"businessName\"]]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"discover__merchant-card__label discover__merchant-card__label--generic\"],[12],[2,\"\\n        \"],[1,[32,1,[\"businessName\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"discover__merchant-card__label\"],[12],[2,\"\\n    \"],[1,[32,1,[\"businessName\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/discover/merchant-card.hbs"
    }
  });
  _exports.default = _default;
});