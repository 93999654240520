define("egift-frontend/templates/components/share-group-gift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EtJIozRC",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[32,0,[\"shareUrlDisplay\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"share-group-gift__header\"],[12],[1,[30,[36,0],[\"shareGroupGift.shareLink\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"share-group-gift__subtitle\"],[12],[1,[30,[36,0],[\"shareGroupGift.shareWithOthers\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"share-group-gift__share-link-container\"],[12],[2,\"\\n    \"],[8,\"input\",[[16,1,[32,0,[\"inputElementId\"]]],[24,0,\"share-group-gift__share-link\"],[24,\"readonly\",\"\"]],[[\"@value\"],[[32,0,[\"shareUrlDisplay\"]]]],null],[2,\"\\n      \"],[11,\"div\"],[24,0,\"share-group-gift_copy-icon hide-from-print-version\"],[4,[38,1],[\"click\",[32,0,[\"copyUrl\"]]],null],[12],[1,[30,[36,2],[\"copy\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"on\",\"inline-svg\",\"if\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/share-group-gift.hbs"
    }
  });
  _exports.default = _default;
});