define("egift-frontend/utils/images", ["exports", "egift-frontend/utils/query-string"], function (_exports, _queryString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transformImage = transformImage;
  function transformImage(url, params) {
    var width = params.width,
      height = params.height;
    if (width === undefined && height === undefined) {
      (false && !(false) && Ember.assert('Must specify a width and/or height'));
      return url;
    }
    return "".concat(url).concat((0, _queryString.toQueryString)(params));
  }
});