define("egift-frontend/templates/components/modal/add-more-funds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6hRsNh4o",
    "block": "{\"symbols\":[\"@onDialogDismissed\",\"@giftInfo\",\"@orderConfiguration\",\"@currentGiftCard\",\"@transition\"],\"statements\":[[11,\"template\"],[4,[38,0],null,null],[12],[2,\"\\n  \"],[11,\"market-modal-partial\"],[4,[38,1],[\"marketDialogDismissed\",[32,1]],null],[12],[2,\"\\n    \"],[10,\"market-header\"],[14,0,\"modal__header\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,\"slot\",\"actions\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"main\"],[12],[2,\"\\n      \"],[8,\"reload-form\",[],[[\"@giftInfo\",\"@orderConfiguration\",\"@prefilledGiftCard\",\"@hideCardInfo\",\"@transition\"],[[32,2],[32,3],[32,4],true,[32,5]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"open-modal\",\"on\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/modal/add-more-funds.hbs"
    }
  });
  _exports.default = _default;
});