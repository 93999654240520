define("egift-frontend/templates/components/date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7yTM1fux",
    "block": "{\"symbols\":[\"active\",\"@enabled\",\"@validationMessage\",\"@placeholder\",\"@value\"],\"statements\":[[8,\"validation-tooltip\",[],[[\"@enabled\",\"@message\"],[[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"pikaday-input\",[[16,0,[31,[\"text-input text-input--fill t-delivery-date \",[30,[36,0],[[32,1],\"text-input--error\"],null]]]],[16,\"placeholder\",[32,4]]],[[\"@format\",\"@minDate\",\"@maxDate\",\"@position\",\"@value\",\"@i18n\",\"@firstDay\",\"@reposition\",\"@theme\",\"@onSelection\"],[[32,0,[\"pikadayConfig\",\"dateFormat\"]],[32,0,[\"minDate\"]],[32,0,[\"maxDate\"]],\"top left\",[32,5],[32,0,[\"pikadayConfig\",\"i18n\"]],[32,0,[\"pikadayConfig\",\"firstDay\"]],false,\"square-pikaday\",[32,0,[\"updateDate\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/date-picker.hbs"
    }
  });
  _exports.default = _default;
});