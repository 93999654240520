define("egift-frontend/templates/components/modal/add-edit-email-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cCj6Mskh",
    "block": "{\"symbols\":[\"@onDialogDismissed\",\"@emailAddress\",\"@recipientSecret\",\"@onSubmit\"],\"statements\":[[11,\"template\"],[4,[38,0],null,null],[12],[2,\"\\n  \"],[11,\"market-modal-partial\"],[4,[38,1],[\"marketDialogDismissed\",[32,1]],null],[12],[2,\"\\n    \"],[10,\"market-header\"],[14,0,\"modal__header\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,\"slot\",\"actions\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"main\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"modal__body\"],[12],[2,\"\\n        \"],[10,\"h2\"],[14,0,\"header-text\"],[12],[1,[30,[36,2],[\"modal.addEditEmailAddress.header\"],null]],[13],[2,\"\\n        \"],[10,\"h3\"],[14,0,\"subheader-text \"],[12],[1,[30,[36,2],[\"modal.addEditEmailAddress.subheader\"],null]],[13],[2,\"\\n        \"],[8,\"email-link-form\",[],[[\"@emailAddress\",\"@recipientSecret\",\"@onSubmit\",\"@buttonText\"],[[32,2],[32,3],[32,4],[30,[36,2],[\"modal.addEditEmailAddress.buttonText\"],null]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"open-modal\",\"on\",\"t\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/modal/add-edit-email-address.hbs"
    }
  });
  _exports.default = _default;
});