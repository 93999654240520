define("egift-frontend/templates/components/modal/payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vh8c2e+i",
    "block": "{\"symbols\":[\"@onDialogDismissed\",\"@model\",\"@processGiftCardSale\",\"@preSubmitValidation\",\"@transition\",\"@editAction\"],\"statements\":[[11,\"template\"],[4,[38,0],null,null],[12],[2,\"\\n  \"],[11,\"market-modal-partial\"],[4,[38,1],[\"marketDialogDismissed\",[32,1]],null],[12],[2,\"\\n    \"],[10,\"market-header\"],[14,0,\"modal__header\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,\"slot\",\"actions\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"main\"],[12],[2,\"\\n      \"],[8,\"payment-container\",[],[[\"@model\",\"@processGiftCardSale\",\"@preSubmitValidation\",\"@transition\",\"@editAction\"],[[32,2],[32,3],[32,4],[32,5],[32,6]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"open-modal\",\"on\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/modal/payment.hbs"
    }
  });
  _exports.default = _default;
});