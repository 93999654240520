define("egift-frontend/utils/is-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isMobileBrowser = isMobileBrowser;
  var mobileUserAgents = [/android/i, /blackberry/i, /chrome webview/i, /iphone/i, /ipad/i, /mobile/i,
  // Mobile Safari
  /samsung browser/i, /webos/i, /windows phone/i];
  function isMobileBrowser(userAgent) {
    return mobileUserAgents.some(function (mobileUserAgent) {
      return userAgent.match(mobileUserAgent);
    });
  }
});