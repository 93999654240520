define("egift-frontend/templates/components/redemption-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "J2wd2tQL",
    "block": "{\"symbols\":[\"&attrs\",\"@iconPath\",\"@name\",\"@value\",\"&default\",\"@actionFn\",\"@actionName\",\"@shouldShowNewView\"],\"statements\":[[6,[37,3],[[32,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"redemption-method__container\"],[17,1],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"redemption-method__row-container\"],[12],[2,\"\\n      \"],[10,\"div\"],[12],[1,[30,[36,1],[[32,2]],[[\"class\"],[\"redemption-method__row-icon\"]]]],[13],[2,\"\\n      \"],[6,[37,3],[[32,4]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"redemption-method__row-value\"],[12],[1,[32,4]],[13]],\"parameters\":[]}]]],[2,\"\\n      \"],[6,[37,3],[[30,[36,4],[[32,7],[32,6]],null]],null,[[\"default\"],[{\"statements\":[[11,\"div\"],[24,0,\"redemption-method__row-action-name\"],[4,[38,2],[\"click\",[32,6]],null],[12],[1,[32,7]],[13]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"redemption-method\"],[17,1],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"redemption-method__separator hide-from-print-version\"],[12],[1,[30,[36,0],[\"waysToRedeem.methodChoiceSeparator\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,1],[[32,2]],[[\"class\"],[\"redemption-method__icon\"]]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"redemption-method__name\"],[12],[1,[32,3]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"redemption-method__value\"],[12],[2,\"\\n      \"],[1,[32,4]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[2,\"    \"],[18,5,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"inline-svg\",\"on\",\"if\",\"and\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/redemption-method.hbs"
    }
  });
  _exports.default = _default;
});