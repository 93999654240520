define("egift-frontend/events/payment/event-catalog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.orderEGift = orderEGift;
  _exports.orderEGiftError = orderEGiftError;
  _exports.orderEGiftSendByTextPhoneNumberValidationIssue = orderEGiftSendByTextPhoneNumberValidationIssue;
  _exports.orderEGiftSendByTextSenderEmailValidationIssue = orderEGiftSendByTextSenderEmailValidationIssue;
  _exports.orderEGiftSuccess = orderEGiftSuccess;
  _exports.reloadGiftCard = reloadGiftCard;
  _exports.reloadGiftCardError = reloadGiftCardError;
  _exports.reloadGiftCardSuccess = reloadGiftCardSuccess;
  _exports.webSdkPaymentSuccess = webSdkPaymentSuccess;
  function orderEGift() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$digitalWallet = _ref.digitalWallet,
      digitalWallet = _ref$digitalWallet === void 0 ? null : _ref$digitalWallet,
      _ref$sendAsGift = _ref.sendAsGift,
      sendAsGift = _ref$sendAsGift === void 0 ? null : _ref$sendAsGift;
    return {
      page_click_description: 'Order EGift',
      page_click_detail: JSON.stringify({
        digitalWallet: digitalWallet,
        sendAsGift: sendAsGift
      })
    };
  }
  function orderEGiftSuccess() {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref2$digitalWallet = _ref2.digitalWallet,
      digitalWallet = _ref2$digitalWallet === void 0 ? null : _ref2$digitalWallet;
    return {
      page_click_description: 'Order EGift: Success',
      page_click_detail: JSON.stringify({
        digitalWallet: digitalWallet
      })
    };
  }
  function orderEGiftError() {
    var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref3$digitalWallet = _ref3.digitalWallet,
      digitalWallet = _ref3$digitalWallet === void 0 ? null : _ref3$digitalWallet;
    return {
      page_click_description: 'Order EGift: Error',
      page_click_detail: JSON.stringify({
        digitalWallet: digitalWallet
      })
    };
  }
  function orderEGiftSendByTextPhoneNumberValidationIssue() {
    var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref4$message = _ref4.message,
      message = _ref4$message === void 0 ? '' : _ref4$message,
      _ref4$merchantToken = _ref4.merchantToken,
      merchantToken = _ref4$merchantToken === void 0 ? '' : _ref4$merchantToken;
    return {
      page_click_description: 'Order EGift: Send By Text Phone Number Validation Issue',
      page_click_detail: "".concat(message, " This is for merchant token: ").concat(merchantToken)
    };
  }
  function orderEGiftSendByTextSenderEmailValidationIssue() {
    var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref5$message = _ref5.message,
      message = _ref5$message === void 0 ? '' : _ref5$message,
      _ref5$merchantToken = _ref5.merchantToken,
      merchantToken = _ref5$merchantToken === void 0 ? '' : _ref5$merchantToken;
    return {
      page_click_description: 'Order EGift: Send By Text Email Validation Issue',
      page_click_detail: "".concat(message, " This is for merchant token: ").concat(merchantToken)
    };
  }
  function webSdkPaymentSuccess(_ref6) {
    var paymentMethodType = _ref6.paymentMethodType;
    return {
      page_click_description: 'Web SDK Payment: Success',
      page_click_detail: JSON.stringify({
        paymentMethodType: paymentMethodType
      })
    };
  }
  function reloadGiftCard() {
    var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref7$digitalWallet = _ref7.digitalWallet,
      digitalWallet = _ref7$digitalWallet === void 0 ? null : _ref7$digitalWallet,
      _ref7$existingBalance = _ref7.existingBalance,
      existingBalance = _ref7$existingBalance === void 0 ? null : _ref7$existingBalance,
      _ref7$reloadAmount = _ref7.reloadAmount,
      reloadAmount = _ref7$reloadAmount === void 0 ? null : _ref7$reloadAmount;
    return {
      page_click_description: 'Reload Gift Card',
      page_click_detail: JSON.stringify({
        digitalWallet: digitalWallet,
        existingBalance: existingBalance,
        reloadAmount: reloadAmount
      })
    };
  }
  function reloadGiftCardSuccess() {
    var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref8$digitalWallet = _ref8.digitalWallet,
      digitalWallet = _ref8$digitalWallet === void 0 ? null : _ref8$digitalWallet;
    return {
      page_click_description: 'Reload Gift Card: Success',
      page_click_detail: JSON.stringify({
        digitalWallet: digitalWallet
      })
    };
  }
  function reloadGiftCardError() {
    var _ref9 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref9$digitalWallet = _ref9.digitalWallet,
      digitalWallet = _ref9$digitalWallet === void 0 ? null : _ref9$digitalWallet;
    return {
      page_click_description: 'Reload Gift Card: Error',
      page_click_detail: JSON.stringify({
        digitalWallet: digitalWallet
      })
    };
  }
});