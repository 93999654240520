define("egift-frontend/templates/components/discover/share-on-twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "A7EqQDPe",
    "block": "{\"symbols\":[\"@disableExternalLibrary\",\"&attrs\",\"&default\"],\"statements\":[[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"script\"],[12],[2,\"\\n    window.twttr = (function(d, s, id) {\\n      var js, fjs = d.getElementsByTagName(s)[0],\\n        t = window.twttr || {};\\n      if (d.getElementById(id)) return t;\\n      js = d.createElement(s);\\n      js.id = id;\\n      js.src = \\\"https://platform.twitter.com/widgets.js\\\";\\n      fjs.parentNode.insertBefore(js, fjs);\\n\\n      t._e = [];\\n      t.ready = function(f) {\\n        t._e.push(f);\\n      };\\n\\n      return t;\\n    }(document, \\\"script\\\", \\\"twitter-wjs\\\"));\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[11,\"a\"],[16,6,[32,0,[\"url\"]]],[17,2],[4,[38,1],[\"click\",[32,0,[\"trackClick\"]]],null],[12],[2,\"\\n  \"],[1,[30,[36,2],[\"icon-twitter-light\"],null]],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unless\",\"on\",\"inline-svg\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/discover/share-on-twitter.hbs"
    }
  });
  _exports.default = _default;
});