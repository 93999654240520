define("egift-frontend/templates/components/gift-card-barcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "70ubSR8a",
    "block": "{\"symbols\":[],\"statements\":[[8,\"barcode\",[],[[\"@value\"],[[32,0,[\"barcodeValue\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/gift-card-barcode.hbs"
    }
  });
  _exports.default = _default;
});