define("egift-frontend/models/gift-card-promotion", ["exports", "moment", "egift-frontend/models/money"], function (_exports, _moment, _money) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes */
  var _default = Ember.Object.extend({
    token: null,
    merchantToken: null,
    promoCode: null,
    promotionType: null,
    fixedDiscount: null,
    percentageDiscount: null,
    minimumLoad: null,
    maximumDiscount: null,
    createdAt: null,
    updatedAt: null,
    expiresAt: null,
    status: null,
    expiration: Ember.computed('expiresAt', function () {
      var expiresAt = this.expiresAt;
      return expiresAt && (0, _moment.default)(expiresAt).format('LL');
    }),
    hasPercentageDiscount: Ember.computed.notEmpty('percentageDiscount'),
    // getDiscount and getDiscountAmount return negative values
    getDiscountAmount: function getDiscountAmount(faceValueAmount) {
      var _this$minimumLoad, _this$maximumDiscount, _this$fixedDiscount;
      var minimumLoadAmount = (_this$minimumLoad = this.minimumLoad) === null || _this$minimumLoad === void 0 ? void 0 : _this$minimumLoad.amount;
      var maximumDiscountAmount = (_this$maximumDiscount = this.maximumDiscount) === null || _this$maximumDiscount === void 0 ? void 0 : _this$maximumDiscount.amount;
      if (minimumLoadAmount && faceValueAmount < minimumLoadAmount) {
        return 0;
      }
      var discountAmount = this.hasPercentageDiscount ? faceValueAmount * (this.percentageDiscount / 100) * -1 : (_this$fixedDiscount = this.fixedDiscount) === null || _this$fixedDiscount === void 0 ? void 0 : _this$fixedDiscount.amount;

      // Discount cannot exceed maximum
      if (maximumDiscountAmount && discountAmount < maximumDiscountAmount) {
        discountAmount = maximumDiscountAmount;
      }

      // Discount cannot exceed gift card value
      if (Math.abs(discountAmount) > faceValueAmount) {
        discountAmount = faceValueAmount * -1;
      }
      return discountAmount;
    },
    getDiscount: function getDiscount(faceValue) {
      return new _money.default({
        amount: this.getDiscountAmount(faceValue.amount),
        currencyCode: faceValue.currencyCode
      });
    }
  }).reopenClass({
    fromResponse: function fromResponse(resp) {
      return this.create({
        token: resp.token,
        merchantToken: resp.merchant_token,
        promoCode: resp.promo_code,
        promotionType: resp.promotion_type,
        fixedDiscount: resp.fixed_discount && new _money.default({
          amount: resp.fixed_discount.amount,
          currencyCode: resp.fixed_discount.currency_code
        }),
        percentageDiscount: resp.percentage_discount,
        minimumLoad: resp.minimum_load && new _money.default({
          amount: resp.minimum_load.amount,
          currencyCode: resp.minimum_load.currency_code
        }),
        maximumDiscount: resp.maximum_discount && new _money.default({
          amount: resp.maximum_discount.amount,
          currencyCode: resp.maximum_discount.currency_code
        }),
        createdAt: (0, _moment.default)(resp.created_at.instant_usec / 1000).toDate(),
        updatedAt: (0, _moment.default)(resp.updated_at.instant_usec / 1000).toDate(),
        expiresAt: resp.expires_at && (0, _moment.default)(resp.expires_at.instant_usec / 1000).toDate(),
        status: resp.status
      });
    }
  });
  /* eslint-enable ember/no-classic-classes */
  _exports.default = _default;
});