define("egift-frontend/events/discovery/event-catalog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deselectBusinessCategoryEvent = deselectBusinessCategoryEvent;
  _exports.hideBusinessCategoryFiltersEvent = hideBusinessCategoryFiltersEvent;
  _exports.initializeAddressFromUrlEvent = initializeAddressFromUrlEvent;
  _exports.locationSelectorClickedSubmitButtonEvent = locationSelectorClickedSubmitButtonEvent;
  _exports.locationSelectorPressedEnterEvent = locationSelectorPressedEnterEvent;
  _exports.numSearchResultsEvent = numSearchResultsEvent;
  _exports.orderConfirmationShareOnFacebookEvent = orderConfirmationShareOnFacebookEvent;
  _exports.orderConfirmationShareOnTwitterEvent = orderConfirmationShareOnTwitterEvent;
  _exports.orderConfirmationShareViaLinkEvent = orderConfirmationShareViaLinkEvent;
  _exports.searchByAddressEvent = searchByAddressEvent;
  _exports.searchByAutocompleteEvent = searchByAutocompleteEvent;
  _exports.searchByGeolocationEvent = searchByGeolocationEvent;
  _exports.searchDiscoveryEvent = searchDiscoveryEvent;
  _exports.selectBusinessCategoryEvent = selectBusinessCategoryEvent;
  _exports.showBusinessCategoryFiltersEvent = showBusinessCategoryFiltersEvent;
  function searchDiscoveryEvent() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$businessCategori = _ref.businessCategories,
      businessCategories = _ref$businessCategori === void 0 ? [] : _ref$businessCategori;
    return {
      page_click_description: 'Discovery: Search',
      page_click_detail: JSON.stringify({
        businessCategories: businessCategories
      })
    };
  }
  function numSearchResultsEvent(numSearchResults) {
    return {
      page_click_description: 'Discovery: Number of Search Results',
      page_click_detail: numSearchResults || 0
    };
  }
  function selectBusinessCategoryEvent(category) {
    return {
      page_click_description: 'Discovery: Business Category Filter: Select',
      page_click_detail: JSON.stringify({
        category: category
      })
    };
  }
  function deselectBusinessCategoryEvent(category) {
    return {
      page_click_description: 'Discovery: Business Category Filter: Deselect',
      page_click_detail: JSON.stringify({
        category: category
      })
    };
  }
  function showBusinessCategoryFiltersEvent() {
    return {
      page_click_description: 'Discovery: Business Category Filter: Show Filters'
    };
  }
  function hideBusinessCategoryFiltersEvent() {
    return {
      page_click_description: 'Discovery: Business Category Filter: Hide Filters'
    };
  }
  function initializeAddressFromUrlEvent() {
    return {
      page_click_description: 'Discovery: Location Selector: Initialize Address From URL'
    };
  }
  function searchByAddressEvent() {
    return {
      page_click_description: 'Discovery: Location Selector: Search By Address'
    };
  }
  function searchByAutocompleteEvent(placeTypes) {
    return {
      page_click_description: 'Discovery: Location Selector: Search By Autocomplete',
      page_click_detail: JSON.stringify(placeTypes)
    };
  }
  function searchByGeolocationEvent() {
    return {
      page_click_description: 'Discovery: Location Selector: Search By Geolocation'
    };
  }
  function locationSelectorClickedSubmitButtonEvent() {
    return {
      page_click_description: 'Discovery: Location Selector: Clicked Submit Button'
    };
  }
  function locationSelectorPressedEnterEvent() {
    return {
      page_click_description: 'Discovery: Location Selector: Pressed Enter'
    };
  }
  function orderConfirmationShareOnFacebookEvent() {
    return {
      page_click_description: 'Discovery: Order Confirmation: Share On Facebook'
    };
  }
  function orderConfirmationShareOnTwitterEvent() {
    return {
      page_click_description: 'Discovery: Order Confirmation: Share On Twitter'
    };
  }
  function orderConfirmationShareViaLinkEvent(url) {
    return {
      page_click_description: 'Discovery: Order Confirmation: Share Via Link',
      page_click_detail: url
    };
  }
});