define("egift-frontend/templates/components/promo-code-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2SCBkKvv",
    "block": "{\"symbols\":[\"active\",\"@prefilledPromotionCode\"],\"statements\":[[10,\"div\"],[14,0,\"promo-code-input\"],[12],[2,\"\\n  \"],[8,\"validation-tooltip\",[],[[\"@enabled\",\"@message\"],[[32,0,[\"hasInvalidPromotionCode\"]],[30,[36,2],[\"discount.codeInvalid\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[11,\"input\"],[16,0,[31,[\"promo-code-input__input text-input text-input--fill \",[30,[36,0],[[32,1],\"text-input--error\"],null]]]],[16,\"placeholder\",[30,[36,2],[\"promotion.promotionCodePlaceholder\"],null]],[16,2,[32,0,[\"promotionCode\"]]],[16,\"disabled\",[32,0,[\"isLoading\"]]],[4,[38,3],[[32,0,[\"prefillPromotionCode\"]],[32,2]],null],[4,[38,1],[\"input\",[32,0,[\"setPromotionCode\"]]],null],[4,[38,1],[\"keydown\",[32,0,[\"handleKeyDown\"]]],null],[12],[13],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,0],[[32,0,[\"isLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"text-input__loading icon-loading-indicator\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"button\"],[16,0,[31,[\"text-input__link link \",[30,[36,0],[[32,0,[\"hasInvalidPromotionCode\"]],\"text-input__link--shift\"],null]]]],[16,\"disabled\",[32,0,[\"shouldDisableApplyButton\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[32,0,[\"applyPromotionCode\"]]],null],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"promotion.applyPromotionCode\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"t\",\"did-insert\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/promo-code-input.hbs"
    }
  });
  _exports.default = _default;
});