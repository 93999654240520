define("egift-frontend/templates/components/merchant-website-link-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CKG3vO3f",
    "block": "{\"symbols\":[\"@merchantWebsites\"],\"statements\":[[6,[37,3],[[32,0,[\"hasMerchantWebsites\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,0,[\"mainMerchantWebsite\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"button button--primary merchant-website-link-button hide-from-print-version\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"openMainMerchantWebsite\"]]],null]],null],[12],[2,\"\\n            \"],[1,[30,[36,2],[\"merchantWebsiteLinkButton.shopOnline\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"button button--primary merchant-website-link-button hide-from-print-version\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"openMerchantWebsitesModal\"]]],null]],null],[12],[2,\"\\n            \"],[1,[30,[36,2],[\"merchantWebsiteLinkButton.shopOnline\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"],[6,[37,3],[[32,0,[\"showMerchantWebsitesModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"modal/merchant-websites\",[],[[\"@onDialogDismissed\",\"@merchantWebsites\"],[[32,0,[\"closeMerchantWebsitesModal\"]],[32,1]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/merchant-website-link-button.hbs"
    }
  });
  _exports.default = _default;
});