define("egift-frontend/utils/country-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    AU: {
      canGiftCardValueExpire: true,
      defaultLocale: 'en-AU',
      forceDefaultLocale: true,
      showGiveAndGetLocal: true
    },
    CA: {
      canGiftCardValueExpire: false,
      defaultLocale: 'en-CA',
      forceDefaultLocale: false,
      showGiveAndGetLocal: true
    },
    GB: {
      canGiftCardValueExpire: false,
      defaultLocale: 'en-GB',
      forceDefaultLocale: true,
      showGiveAndGetLocal: true
    },
    JP: {
      canGiftCardValueExpire: false,
      defaultLocale: 'ja',
      forceDefaultLocale: false,
      postalCode: {
        required: false
      },
      showGiveAndGetLocal: true
    },
    IE: {
      canGiftCardValueExpire: false,
      defaultLocale: 'en-IE',
      forceDefaultLocale: true,
      showGiveAndGetLocal: false
    },
    FR: {
      canGiftCardValueExpire: false,
      defaultLocale: 'fr',
      forceDefaultLocale: true,
      showGiveAndGetLocal: false
    },
    ES: {
      canGiftCardValueExpire: false,
      defaultLocale: 'es-ES',
      forceDefaultLocale: true,
      showGiveAndGetLocal: false
    },
    US: {
      canGiftCardValueExpire: false,
      defaultLocale: 'en',
      forceDefaultLocale: false,
      showGiveAndGetLocal: true
    }
  };
  _exports.default = _default;
});