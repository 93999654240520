define("egift-frontend/templates/components/discover/location-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JoCjo2OG",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"discover__location-selector\"],[17,1],[12],[2,\"\\n  \"],[10,\"label\"],[15,\"for\",[32,0,[\"inputId\"]]],[14,0,\"discover__location-selector__search-label\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"discover.locationSelector.label\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"discover__location-selector__row\"],[12],[2,\"\\n    \"],[8,\"address-input\",[],[[\"@isLoading\",\"@isShowingCurrentLocation\",\"@onSubmit\"],[[32,0,[\"isLoading\"]],[32,0,[\"isUsingCurrentLocation\"]],[32,0,[\"submitAddress\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"geolocation\",\"userBlockedLocationPermission\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"discover__location-selector__row\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"discover__location-selector__use-current-location\"],[16,\"disabled\",[32,0,[\"isUsingCurrentLocation\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[32,0,[\"submitCurrentLocation\"]]],null],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"discover.locationSelector.useCurrentLocation\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"t\",\"unless\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/discover/location-selector.hbs"
    }
  });
  _exports.default = _default;
});