define("egift-frontend/utils/delivery-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DELIVERY_METHOD = void 0;
  var DELIVERY_METHOD = {
    EMAIL: 'EMAIL',
    TEXT: 'TEXT'
  };
  _exports.DELIVERY_METHOD = DELIVERY_METHOD;
});