define("egift-frontend/templates/components/barcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DkQ6I90T",
    "block": "{\"symbols\":[],\"statements\":[[11,\"svg\"],[16,1,[32,0,[\"elementId\"]]],[4,[38,0],[[32,0,[\"renderBarcode\"]]],null],[12],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/barcode.hbs"
    }
  });
  _exports.default = _default;
});