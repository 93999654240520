define("egift-frontend/templates/components/add-more-funds-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8G597dQI",
    "block": "{\"symbols\":[\"@paymentModel\",\"@giftInfo\",\"@orderConfiguration\",\"@currentGiftCard\"],\"statements\":[[10,\"div\"],[14,0,\"view__container hide-from-print-version\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"view__separator\"],[12],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"view__add-funds-button\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,0,[\"onOpenAddMoreFundsModal\"]]],null],[12],[1,[30,[36,1],[\"view.addMoreFunds\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"showAddMoreFundsModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"modal/add-more-funds\",[],[[\"@giftInfo\",\"@orderConfiguration\",\"@currentGiftCard\",\"@onDialogDismissed\",\"@transition\"],[[32,2],[32,3],[32,4],[32,0,[\"closeAddMoreFundsModal\"]],[32,0,[\"onOpenCheckoutModal\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[32,0,[\"showCheckoutModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"modal/payment\",[],[[\"@model\",\"@processGiftCardSale\",\"@preSubmitValidation\",\"@onDialogDismissed\",\"@editAction\"],[[32,1],[32,0,[\"processGiftCardSale\"]],[32,0,[\"preSubmitValidation\"]],[32,0,[\"closeCheckoutModal\"]],[32,0,[\"onEdit\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/add-more-funds-button.hbs"
    }
  });
  _exports.default = _default;
});