define("egift-frontend/templates/components/yield-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kkGVHCE7",
    "block": "{\"symbols\":[\"@isLoading\",\"@disabled\",\"@onclickFn\",\"@label\",\"&default\",\"@showYield\"],\"statements\":[[6,[37,0],[[32,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,5,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[31,[\"yield-button \",[30,[36,0],[[32,1],\"yield-button--loading\"],null]]]],[16,\"disabled\",[32,2]],[24,4,\"button\"],[4,[38,1],[\"click\",[32,3]],null],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"yield-button-text\"],[12],[1,[32,4]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/yield-button.hbs"
    }
  });
  _exports.default = _default;
});