define("egift-frontend/templates/components/phone-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ow5pW9Gl",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[10,\"div\"],[14,0,\"phone-input-container text-input text-input--fill\"],[12],[2,\"\\n       \"],[10,\"div\"],[12],[1,[30,[36,0],[\"phone\"],[[\"class\"],[\"redemption-method__row-icon\"]]]],[13],[2,\"\\n       \"],[11,\"input\"],[24,0,\"phone-input\"],[16,\"placeholder\",[32,0,[\"exampleNumber\"]]],[16,2,[30,[36,1],[[32,0,[\"formattedPhoneNumber\"]]],null]],[17,1],[24,4,\"tel\"],[4,[38,2],[\"keydown\",[32,0,[\"onKeydown\"]]],null],[4,[38,2],[\"input\",[32,0,[\"onInputChange\"]]],null],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"readonly\",\"on\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/phone-input.hbs"
    }
  });
  _exports.default = _default;
});