define("egift-frontend/templates/components/add-to-apple-wallet-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6qzpngRW",
    "block": "{\"symbols\":[\"@addToAppleWalletUrl\"],\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],[32,0,[\"deviceInfo\",\"isIphone\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"add-to-apple-wallet-container\"],[12],[2,\"\\n    \"],[10,\"a\"],[15,6,[31,[[32,1]]]],[14,\"role\",\"button\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,0,[\"localizedImageName\"]]],[[\"width\",\"height\",\"role\",\"title\"],[\"155px\",\"49px\",\"img\",[30,[36,0],[\"appleVas.addToWalletButton\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"svg-jar\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/add-to-apple-wallet-button.hbs"
    }
  });
  _exports.default = _default;
});