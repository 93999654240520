define("egift-frontend/templates/components/history-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hSVgSSRV",
    "block": "{\"symbols\":[\"&attrs\",\"@historyEvent\"],\"statements\":[[11,\"div\"],[16,0,[30,[36,0],[[32,0,[\"useNewViewGiftCardPage\"]],\"history-event\",\"history-event-old\"],null]],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"history-event__text\"],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[30,[36,0],[[32,0,[\"useNewViewGiftCardPage\"]],\"history-event__event-type\",\"\"],null]],[12],[1,[32,0,[\"eventTypeText\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[30,[36,0],[[32,0,[\"useNewViewGiftCardPage\"]],\"history-event__date\",\"history-event__date-old\"],null]],[12],[1,[30,[36,1],[[32,2,[\"createdAt\"]]],[[\"format\"],[\"lll\"]]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[32,0,[\"amountClass\"]]],[12],[1,[30,[36,2],[[32,2,[\"amount\"]]],[[\"allowNegative\"],[true]]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"format-date\",\"format-money\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/history-event.hbs"
    }
  });
  _exports.default = _default;
});