define("egift-frontend/utils/browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getQueryString = getQueryString;
  _exports.goBack = goBack;
  _exports.setTitle = setTitle;
  _exports.windowOpen = windowOpen;
  function getQueryString() {
    return location.search;
  }
  function goBack() {
    window.history.back();
  }
  function windowOpen() {
    var _window;
    return (_window = window).open.apply(_window, arguments);
  }
  function setTitle(title) {
    document.title = title;
  }
});