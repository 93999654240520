define("egift-frontend/templates/components/add-to-square-profile-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wFktWvb/",
    "block": "{\"symbols\":[\"@addToSquareProfileUrl\"],\"statements\":[[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"market-button\"],[14,0,\"add-to-square-profile-button__button\"],[15,6,[32,1]],[12],[1,[30,[36,0],[\"waysToRedeem.addToSquareProfile\"],null]],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"add-to-square-profile-button__square-profile-description hide-from-print-version\"],[12],[1,[30,[36,0],[\"waysToRedeem.squareProfileDescription\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/add-to-square-profile-button.hbs"
    }
  });
  _exports.default = _default;
});