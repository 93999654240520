define("egift-frontend/events/check-balance/event-catalog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkBalanceLookupError = checkBalanceLookupError;
  _exports.checkBalanceLookupSuccess = checkBalanceLookupSuccess;
  _exports.checkBalanceSubmitPostCaptcha = checkBalanceSubmitPostCaptcha;
  _exports.checkBalanceSubmitPreCaptcha = checkBalanceSubmitPreCaptcha;
  function checkBalanceSubmitPreCaptcha(_ref) {
    var captchaEnabled = _ref.captchaEnabled;
    return {
      page_click_description: 'Check Balance: Attempt Submit',
      page_click_detail: JSON.stringify({
        captchaEnabled: captchaEnabled
      })
    };
  }
  function checkBalanceSubmitPostCaptcha(_ref2) {
    var captchaEnabled = _ref2.captchaEnabled;
    return {
      page_click_description: 'Check Balance: Submit',
      page_click_detail: JSON.stringify({
        captchaEnabled: captchaEnabled
      })
    };
  }
  function checkBalanceLookupSuccess(_ref3) {
    var captchaEnabled = _ref3.captchaEnabled;
    return {
      page_click_description: 'Check Balance: Lookup Success',
      page_click_detail: JSON.stringify({
        captchaEnabled: captchaEnabled
      })
    };
  }
  function checkBalanceLookupError(_ref4) {
    var captchaEnabled = _ref4.captchaEnabled,
      error = _ref4.error;
    return {
      page_click_description: 'Check Balance: Lookup Error',
      page_click_detail: JSON.stringify({
        captchaEnabled: captchaEnabled,
        error: error
      })
    };
  }
});