define("egift-frontend/templates/components/transaction-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0SNkCIIf",
    "block": "{\"symbols\":[\"historyEvent\",\"@transactions\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"history-event\",[[24,0,\"transaction-history__item\"]],[[\"@historyEvent\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/transaction-history.hbs"
    }
  });
  _exports.default = _default;
});