define("egift-frontend/utils/business-categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    translationKey: 'businessCategory.foodAndDrink',
    token: 'FOOD_AND_DRINK'
  }, {
    translationKey: 'businessCategory.healthCareAndFitness',
    token: 'HEALTH_CARE_AND_FITNESS'
  }, {
    translationKey: 'businessCategory.beautyAndPersonalcare',
    token: 'BEAUTY_AND_PERSONAL_CARE'
  }, {
    translationKey: 'businessCategory.professionalServices',
    token: 'PROFESSIONAL_SERVICES'
  }, {
    translationKey: 'businessCategory.charitiesEducationAndMembership',
    token: 'CHARITIES_EDUCATION_AND_MEMBERSHIP'
  }, {
    translationKey: 'businessCategory.retail',
    token: 'RETAIL'
  }, {
    translationKey: 'businessCategory.leisureAndEntertainment',
    token: 'LEISURE_AND_ENTERTAINMENT'
  }, {
    translationKey: 'businessCategory.homeAndRepair',
    token: 'HOME_AND_REPAIR'
  }, {
    translationKey: 'businessCategory.transportation',
    token: 'TRANSPORTATION'
  }, {
    translationKey: 'businessCategory.other',
    token: 'CASUAL_USE'
  }];
  _exports.default = _default;
});