define("egift-frontend/templates/components/validation-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "awRhasvx",
    "block": "{\"symbols\":[\"@errorMessageCustomClassNames\",\"@message\",\"@alertIconCustomClassNames\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"validation-tooltip\"],[12],[2,\"\\n  \"],[18,4,[[32,0,[\"isActive\"]]]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isActive\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"validation-tooltip__error \",[32,0,[\"positionClass\"]],\" \",[32,1]]]],[12],[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[15,0,[31,[[32,3]]]],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"alert\"],[[\"class\"],[\"validation-tooltip__icon\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"if\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/validation-tooltip.hbs"
    }
  });
  _exports.default = _default;
});