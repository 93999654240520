define("egift-frontend/templates/components/multi-email-text-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6AqNSpSN",
    "block": "{\"symbols\":[\"@active\",\"&attrs\"],\"statements\":[[8,\"textarea\",[[16,0,[31,[\"text-input text-input--fill textarea-input \",[30,[36,0],[[32,1],\"text-input--error\"],null]]]],[17,2],[4,[38,2],[\"change\",[32,0,[\"onChange\"]]],null]],[[\"@value\"],[[30,[36,1],[[32,0,[\"formattedValue\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"readonly\",\"on\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/multi-email-text-area.hbs"
    }
  });
  _exports.default = _default;
});