define("egift-frontend/templates/components/app-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kIYXT54S",
    "block": "{\"symbols\":[],\"statements\":[[10,\"footer\"],[14,0,\"app-footer\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"app-footer__section\"],[12],[2,\"\\n\"],[2,\"    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"footer.text\"],[[\"localizedGiftcardsLink\",\"htmlSafe\"],[[32,0,[\"localizedGiftcardsLink\"]],true]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"app-footer__section\"],[12],[2,\"\\n    \"],[10,\"a\"],[15,6,[32,0,[\"privacyPolicyUrl\"]]],[14,\"target\",\"_blank\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"footer.privacyPolicy\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/app-footer.hbs"
    }
  });
  _exports.default = _default;
});