define("egift-frontend/validators/recipient-emails", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MAX_NUMBER_CONTRIBUTOR_EMAILS = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var MIN_QUANTITY_EMAILS = 2;
  // requirement from legal. see https://block.atlassian.net/browse/GC-4153 for details
  var MAX_NUMBER_CONTRIBUTOR_EMAILS = 50;

  // Email validation regex used by Auth0
  // https://github.com/auth0/lock/blob/4aaefa4a9ed00423e665e0743a7b0a952c6a267d/src/field/email.js#L7
  _exports.MAX_NUMBER_CONTRIBUTOR_EMAILS = MAX_NUMBER_CONTRIBUTOR_EMAILS;
  function isValidEmail(email) {
    var emailPattern = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;
    return emailPattern.test(email);
  }
  var _default = _base.default.extend({
    validate: function validate(value, _ref, model) {
      var maxQuantity = _ref.maxQuantity;
      var isGroupGiftSale = model.get('isGroupGiftSale');
      var maxQuantityForGroupGiftCard = maxQuantity > MAX_NUMBER_CONTRIBUTOR_EMAILS ? MAX_NUMBER_CONTRIBUTOR_EMAILS : maxQuantity;
      var maxQuantityToUse = isGroupGiftSale ? maxQuantityForGroupGiftCard : maxQuantity;
      var emails = (isGroupGiftSale ? model.get('contributorEmails') : model.get('recipientEmails')) || [];
      if (emails.length > maxQuantityToUse) {
        return {
          isValid: false,
          message: model.get('intl').t('giftInfoForm.maxEmailAddressesValidation', {
            max: maxQuantityToUse
          })
        };
      }
      if (!isGroupGiftSale && emails.length < MIN_QUANTITY_EMAILS) {
        return {
          isValid: false,
          message: model.get('intl').t('giftInfoForm.minEmailAddressesValidation', {
            min: MIN_QUANTITY_EMAILS
          })
        };
      }
      var _iterator = _createForOfIteratorHelper(emails),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var email = _step.value;
          var trimmedEmail = email.replace(/\s/g, '');
          if (!isValidEmail(trimmedEmail)) {
            return {
              isValid: false,
              message: model.get('intl').t('giftInfoForm.invalidEmailAddressValidation', {
                email: trimmedEmail
              })
            };
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return true;
    }
  });
  _exports.default = _default;
});