define("egift-frontend/validators/names", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    validate: function validate(value, _, model) {
      var isValid = value && value.trim().length > 0;
      return {
        isValid: isValid,
        message: !isValid ? model.get('intl').t('errors.requiredField') : undefined
      };
    }
  });
  _exports.default = _default;
});