define("egift-frontend/templates/components/group-gift-contributors-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5Dy+FjBp",
    "block": "{\"symbols\":[\"@groupContributionDetails\"],\"statements\":[[6,[37,2],[[32,1,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"group-gift-contributors__container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"group__header\"],[12],[1,[30,[36,1],[\"groupGiftContributors.header\"],null]],[2,\" (\"],[1,[32,1,[\"length\"]]],[2,\")\"],[13],[2,\"\\n    \"],[8,\"group-gift-contributor-list\",[],[[\"@groupContributionDetails\"],[[32,0,[\"truncatedGroupContributionDetails\"]]]],null],[2,\"\\n\"],[6,[37,2],[[32,0,[\"shouldTruncateGroupContributionDetails\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"group__button-container\"],[12],[2,\"\\n        \"],[11,\"market-button\"],[24,0,\"group-gift-contributors__view-all-button\"],[4,[38,0],[\"click\",[32,0,[\"openGroupGiftContributorsModal\"]]],null],[12],[1,[30,[36,1],[\"groupGiftContributors.viewAll\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"showGroupGiftContributorsModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"modal/group-gift-contributors-modal\",[],[[\"@onDialogDismissed\",\"@groupContributionDetails\"],[[32,0,[\"closeGroupGiftContributorsModal\"]],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/group-gift-contributors-section.hbs"
    }
  });
  _exports.default = _default;
});