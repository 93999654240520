define("egift-frontend/templates/components/segmented-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6XudISmv",
    "block": "{\"symbols\":[\"choice\",\"index\",\"@selected\",\"@disabled\",\"@onSelect\",\"&attrs\",\"@choices\"],\"statements\":[[11,\"div\"],[24,0,\"segmented-control\"],[17,6],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[16,0,[31,[\"segmented-control__segment\\n                \",[30,[36,0],[[30,[36,1],[[32,1,[\"value\"]],[32,3]],null],\"segmented-control__segment--selected\"],null],\"\\n                \",[30,[36,0],[[32,1,[\"smaller\"]],\"segmented-control__segment--smaller\"],null]]]],[16,\"disabled\",[32,4]],[24,4,\"button\"],[4,[38,3],[\"click\",[30,[36,2],[[32,5],[32,1,[\"value\"]]],null]],null],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"segmented-control__segment__label\"],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n\"],[6,[37,0],[[32,1,[\"sublabel\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[31,[\"segmented-control__sublabel\\n            \",[30,[36,0],[[32,1,[\"sublabelHighlighted\"]],\"segmented-control__sublabel--highlighted\"],null]]]],[12],[2,\"\\n          \"],[1,[32,1,[\"sublabel\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"eq\",\"fn\",\"on\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/segmented-control.hbs"
    }
  });
  _exports.default = _default;
});