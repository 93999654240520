define("egift-frontend/templates/merchant/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FNMR7WOJ",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"l-page-step-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-step-number\"],[12],[1,[30,[36,0],[\"link.pageOne\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"hero-text hero-text--with-padding\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"link.heroText\"],null]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hero-text__secondary\"],[12],[1,[30,[36,0],[\"link.heroSecondary\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"narrow-container\"],[12],[2,\"\\n  \"],[8,\"phone-link-form\",[],[[\"@rawPhoneNumber\",\"@recipientSecret\",\"@onSubmit\",\"@buttonText\"],[[32,1,[\"giftCard\",\"egiftDetails\",\"recipientPhoneNumber\"]],[32,1,[\"recipientSecret\"]],[32,0,[\"onSubmit\"]],[30,[36,0],[\"common.next\"],null]]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"l-center\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"link\"]],[[\"@route\",\"@model\"],[\"merchant.view\",[32,1,[\"recipientSecret\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"link.noThanks\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/merchant/link.hbs"
    }
  });
  _exports.default = _default;
});