define("egift-frontend/utils/group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.constructGroupGiftUrl = constructGroupGiftUrl;
  function constructGroupGiftUrl(props) {
    var merchantToken = props.merchantToken,
      senderSecret = props.senderSecret,
      includeProtocol = props.includeProtocol,
      window = props.window;
    if (!merchantToken || !senderSecret || !window) {
      return undefined;
    }
    var origin = includeProtocol ? window.location.origin : window.location.host;
    return "".concat(origin, "/gift/").concat(merchantToken, "/group/").concat(senderSecret);
  }
});