define("egift-frontend/utils/recaptcha", ["exports", "egift-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.injectCaptchaScriptTag = injectCaptchaScriptTag;
  var ON_LOAD_CALLBACK_KEY = '__square_egift_recaptcha_onload';
  var isInjected = false;
  var isLoaded = false;
  var callbacks = [];
  window[ON_LOAD_CALLBACK_KEY] = function () {
    isLoaded = true;
    for (var _i = 0, _callbacks = callbacks; _i < _callbacks.length; _i++) {
      var callback = _callbacks[_i];
      callback();
    }
  };
  function injectCaptchaScriptTag(callback) {
    if (!isLoaded) {
      callbacks.push(callback);
    } else {
      callback();
    }
    if (!isInjected) {
      isInjected = true;
      var scriptTag = document.createElement('script');
      // Always use v3 site key here - it works for both v3 and v2.
      // https://developers.google.com/recaptcha/docs/faq#can-i-run-recaptcha-v2-and-v3-on-the-same-page
      scriptTag.src = "https://www.google.com/recaptcha/api.js?render=".concat(_environment.default.APP.RECAPTCHA_SITE_KEY_V3, "&onload=").concat(ON_LOAD_CALLBACK_KEY);
      document.body.appendChild(scriptTag);
    }

    // Small hack so we can differentiate between real JS and test stubs, and handle async behavior correctly in tests
    return true;
  }
});