define("egift-frontend/templates/components/hover-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4L4wMIbV",
    "block": "{\"symbols\":[\"&default\",\"@iconName\"],\"statements\":[[10,\"div\"],[14,0,\"hover-tooltip\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"displayTooltip\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,\"tooltip-box\",\"\"],[14,0,\"hover-tooltip__box\"],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[11,\"span\"],[4,[38,1],[\"mouseover\",[32,0,[\"toggleTooltip\"]]],null],[4,[38,1],[\"mouseout\",[32,0,[\"toggleTooltip\"]]],null],[12],[2,\"\\n    \"],[1,[30,[36,2],[[32,2]],[[\"class\"],[\"hover-tooltip__icon\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"inline-svg\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/hover-tooltip.hbs"
    }
  });
  _exports.default = _default;
});