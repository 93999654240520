define("egift-frontend/events/view/event-catalog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.viewGiftCardAddMoreFundsClick = viewGiftCardAddMoreFundsClick;
  _exports.viewGiftCardClick = viewGiftCardClick;
  _exports.viewGiftCardCopyGanClick = viewGiftCardCopyGanClick;
  _exports.viewGiftCardEditEmailAddressClick = viewGiftCardEditEmailAddressClick;
  _exports.viewGiftCardEditPhoneNumberClick = viewGiftCardEditPhoneNumberClick;
  _exports.viewGiftCardSaveEmailAddressClick = viewGiftCardSaveEmailAddressClick;
  _exports.viewGiftCardSavePhoneNumberClick = viewGiftCardSavePhoneNumberClick;
  _exports.viewGiftCardShopOnlineClick = viewGiftCardShopOnlineClick;
  _exports.viewGiftCardSquareOnlineLinkClick = viewGiftCardSquareOnlineLinkClick;
  function viewGiftCardClick(_ref) {
    var giftCardToken = _ref.giftCardToken,
      sosUrlsCount = _ref.sosUrlsCount,
      hasPhoneNumber = _ref.hasPhoneNumber,
      isGiftCardSentViaText = _ref.isGiftCardSentViaText;
    return {
      page_click_description: 'View Gift Card',
      page_click_detail: JSON.stringify({
        giftCardToken: giftCardToken,
        hasPhoneNumber: hasPhoneNumber,
        sosUrlsCount: sosUrlsCount,
        isGiftCardSentViaText: isGiftCardSentViaText
      })
    };
  }
  function viewGiftCardShopOnlineClick(_ref2) {
    var giftCardToken = _ref2.giftCardToken,
      sosUrls = _ref2.sosUrls;
    return {
      page_click_description: 'View Gift Card: Shop Online',
      page_click_detail: JSON.stringify({
        giftCardToken: giftCardToken,
        sosUrls: sosUrls,
        sosUrlsCount: sosUrls.length
      })
    };
  }
  function viewGiftCardSquareOnlineLinkClick(_ref3) {
    var giftCardToken = _ref3.giftCardToken,
      sosUrl = _ref3.sosUrl;
    return {
      page_click_description: 'View Gift Card: Square Online Link',
      page_click_detail: JSON.stringify({
        giftCardToken: giftCardToken,
        sosUrl: sosUrl
      })
    };
  }
  function viewGiftCardCopyGanClick(_ref4) {
    var giftCardToken = _ref4.giftCardToken;
    return {
      page_click_description: 'View Gift Card: Copy GAN',
      page_click_detail: JSON.stringify({
        giftCardToken: giftCardToken
      })
    };
  }
  function viewGiftCardAddMoreFundsClick(_ref5) {
    var giftCardToken = _ref5.giftCardToken;
    return {
      page_click_description: 'View Gift Card: Add More Funds',
      page_click_detail: JSON.stringify({
        giftCardToken: giftCardToken
      })
    };
  }
  function viewGiftCardEditPhoneNumberClick(_ref6) {
    var giftCardToken = _ref6.giftCardToken;
    return {
      page_click_description: 'View Gift Card: Edit Phone Number',
      page_click_detail: JSON.stringify({
        giftCardToken: giftCardToken
      })
    };
  }
  function viewGiftCardSavePhoneNumberClick(_ref7) {
    var giftCardToken = _ref7.giftCardToken;
    return {
      page_click_description: 'View Gift Card: Save Phone Number',
      page_click_detail: JSON.stringify({
        giftCardToken: giftCardToken
      })
    };
  }
  function viewGiftCardEditEmailAddressClick(_ref8) {
    var giftCardToken = _ref8.giftCardToken;
    return {
      page_click_description: 'View Gift Card: Edit Email Address',
      page_click_detail: JSON.stringify({
        giftCardToken: giftCardToken
      })
    };
  }
  function viewGiftCardSaveEmailAddressClick(_ref9) {
    var giftCardToken = _ref9.giftCardToken;
    return {
      page_click_description: 'View Gift Card: Save Email Address',
      page_click_detail: JSON.stringify({
        giftCardToken: giftCardToken
      })
    };
  }
});