define("egift-frontend/utils/locale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GENERIC_DEFAULT_LOCALE = void 0;
  _exports.chooseLocale = chooseLocale;
  _exports.generateFallbackArray = generateFallbackArray;
  _exports.getPrimaryLanguageCode = getPrimaryLanguageCode;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var GENERIC_DEFAULT_LOCALE = 'en';

  // Finds the most-preferred locale that is supported by the app
  //
  // Be careful when updating this, as it's a bit of a minefield.
  // - navigator.languages is supported by modern Chrome / Firefox / Safari 11
  // - navigator.language is supported by Safari < 11 / Edge / IE11
  // - navigator.userLanguage is supported by IE < 11
  _exports.GENERIC_DEFAULT_LOCALE = GENERIC_DEFAULT_LOCALE;
  function getBrowserLocales() {
    return [].concat(_toConsumableArray(navigator.languages || []), [navigator.language, navigator.userLanguage]).compact().map(function (locale) {
      return locale.toLowerCase();
    });
  }

  // 'ja-JP' => ['ja-JP', 'ja']
  // https://tools.ietf.org/html/rfc4647#section-3.4
  function generateFallbackArray(locale) {
    var tags = locale.split('-');
    var fallbackArray = [];
    while (tags.length > 0) {
      fallbackArray.push(tags.join('-'));
      tags.pop();
    }
    return fallbackArray;
  }
  function chooseLocale(availableLocales, countryConfig) {
    var lang = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    var localeFromLangParam = generateFallbackArray(lang).find(function (locale) {
      return availableLocales.includes(locale.toLowerCase());
    });

    // a valid lang query param overrides the locale
    if (localeFromLangParam) {
      return localeFromLangParam;
    }
    if (countryConfig.forceDefaultLocale) {
      return countryConfig.defaultLocale;
    }
    var preferredLocales = getBrowserLocales().flatMap(generateFallbackArray);
    return preferredLocales.find(function (locale) {
      return availableLocales.includes(locale);
    }) || countryConfig.defaultLocale || GENERIC_DEFAULT_LOCALE;
  }

  // 'en' => 'en'
  // 'en-US' => 'en'
  // https://tools.ietf.org/html/rfc5646#section-2.2.1
  function getPrimaryLanguageCode() {
    var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return locale.split('-')[0] || GENERIC_DEFAULT_LOCALE;
  }
});