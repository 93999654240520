define("egift-frontend/templates/merchant/order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1xXxAYTq",
    "block": "{\"symbols\":[],\"statements\":[[8,\"recaptcha\",[],[[\"@actionName\",\"@useV3\"],[\"payOrder\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"model\",\"orderConfiguration\",\"globalPromotion\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"global-promotion-banner\",[],[[\"@promotion\"],[[32,0,[\"model\",\"orderConfiguration\",\"globalPromotion\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,\"gift-info-header\",[],[[\"@themes\",\"@selectedTheme\",\"@selectedGiftType\",\"@onChangeTheme\",\"@showHeroText\"],[[32,0,[\"model\",\"orderConfiguration\",\"egiftThemes\"]],[32,0,[\"model\",\"giftInfo\",\"egiftTheme\"]],[32,0,[\"model\",\"giftInfo\",\"giftType\"]],[32,0,[\"updateEgiftTheme\"]],true]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[8,\"gift-info-form\",[],[[\"@orderConfiguration\",\"@selectedGiftType\",\"@prefilledPromotionCode\",\"@giftInfo\",\"@onChangeGiftType\",\"@showBuyerInputs\",\"@onSubmit\"],[[32,0,[\"model\",\"orderConfiguration\"]],[32,0,[\"model\",\"giftInfo\",\"giftType\"]],[32,0,[\"promo_code\"]],[32,0,[\"model\",\"giftInfo\"]],[32,0,[\"updateGiftType\"]],true,[32,0,[\"onSubmit\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/merchant/order.hbs"
    }
  });
  _exports.default = _default;
});