define("egift-frontend/components/sq-icon16-ui-chevron-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var r = Ember._setComponentTemplate(Ember.HTMLBars.template(
  /*
    <svg ...attributes xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" version="1.1">
  	<path d="M 1.683 4.055 C 1.526 4.106, 1.455 4.153, 1.305 4.303 C 1.082 4.526, 1.006 4.710, 1.008 5.028 C 1.009 5.460, 0.805 5.230, 4.286 8.710 C 6.817 11.240, 7.431 11.838, 7.565 11.907 C 7.710 11.982, 7.763 11.992, 8 11.992 C 8.234 11.992, 8.292 11.981, 8.435 11.911 C 8.573 11.843, 9.113 11.316, 11.741 8.684 C 15.265 5.155, 14.994 5.463, 14.994 4.983 C 14.994 4.749, 14.986 4.712, 14.891 4.538 C 14.654 4.099, 14.191 3.908, 13.714 4.051 L 13.493 4.117 10.754 6.854 L 8.015 9.590 5.291 6.871 C 2.299 3.885, 2.470 4.037, 2.063 4.006 C 1.925 3.996, 1.826 4.008, 1.683 4.055" fill-rule="evenodd"/>
  </svg>
  */
  {
    "id": "7/2ldJq9",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"svg\"],[17,1],[24,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[24,\"width\",\"16\"],[24,\"height\",\"16\"],[24,\"viewBox\",\"0 0 16 16\"],[24,\"version\",\"1.1\"],[12],[2,\"\\n\\t\"],[10,\"path\"],[14,\"d\",\"M 1.683 4.055 C 1.526 4.106, 1.455 4.153, 1.305 4.303 C 1.082 4.526, 1.006 4.710, 1.008 5.028 C 1.009 5.460, 0.805 5.230, 4.286 8.710 C 6.817 11.240, 7.431 11.838, 7.565 11.907 C 7.710 11.982, 7.763 11.992, 8 11.992 C 8.234 11.992, 8.292 11.981, 8.435 11.911 C 8.573 11.843, 9.113 11.316, 11.741 8.684 C 15.265 5.155, 14.994 5.463, 14.994 4.983 C 14.994 4.749, 14.986 4.712, 14.891 4.538 C 14.654 4.099, 14.191 3.908, 13.714 4.051 L 13.493 4.117 10.754 6.854 L 8.015 9.590 5.291 6.871 C 2.299 3.885, 2.470 4.037, 2.063 4.006 C 1.925 3.996, 1.826 4.008, 1.683 4.055\"],[14,\"fill-rule\",\"evenodd\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {}
  }), Ember._templateOnlyComponent());
  _exports.default = r;
});