define("egift-frontend/templates/components/phone-link-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uPwLKczf",
    "block": "{\"symbols\":[\"hasError\",\"@buttonText\"],\"statements\":[[10,\"form\"],[15,\"onsubmit\",[30,[36,0],[[32,0,[\"submit\"]]],null]],[14,\"novalidate\",\"\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field-group\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field-group__row\"],[12],[2,\"\\n      \"],[8,\"validation-tooltip\",[],[[\"@enabled\",\"@errorMessageCustomClassNames\",\"@message\",\"@position\"],[[30,[36,1],[[32,0,[\"hasValidationError\"]],[32,0,[\"hasUserSubmitted\"]]],null],\"validation-tooltip__error--visible\",[30,[36,2],[\"errors.invalidPhone\"],null],\"top\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"phone-input\",[[16,0,[30,[36,3],[[32,1],\"text-input--error\"],null]]],[[\"@country\",\"@currentPhoneNumber\",\"@onChange\"],[[32,0,[\"merchant\",\"country\"]],[30,[36,4],[[32,0,[\"phoneNumber\"]]],null],[32,0,[\"updatePhoneNumber\"]]]],null],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field-group__row\"],[12],[2,\"\\n      \"],[10,\"button\"],[15,0,[31,[\"button button--primary button--fill \",[30,[36,3],[[32,0,[\"isLoading\"]],\"button--loading\"],null]]]],[15,\"disabled\",[32,0,[\"isLoading\"]]],[14,4,\"submit\"],[12],[2,\"\\n        \"],[10,\"i\"],[12],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"button__text\"],[12],[1,[32,2]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"prevent-default\",\"and\",\"t\",\"if\",\"readonly\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/phone-link-form.hbs"
    }
  });
  _exports.default = _default;
});