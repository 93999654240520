define("egift-frontend/templates/merchant/check-balance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jWFhVPBZ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"check-balance-container\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "egift-frontend/templates/merchant/check-balance.hbs"
    }
  });
  _exports.default = _default;
});