define("egift-frontend/instance-initializers/datadog-rum", ["exports", "@datadog/browser-rum", "egift-frontend/utils/environment"], function (_exports, _browserRum, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  var APPLICATION_ID = 'ac22af1d-88d0-42fc-b0d2-c04fd5c48a1d';
  var CLIENT_TOKEN = 'pub8ff54dc9065e6027aec8e51c3856f658';
  var SERVICE = 'egift-frontend';
  var SITE = 'datadoghq.com';
  function initialize() {
    var environment = (0, _environment.getEnvironmentName)();

    // we only want to initialize datadog in staging and production environments
    if (!(environment === _environment.ENVIRONMENT_NAME.STAGING || environment === _environment.ENVIRONMENT_NAME.PRODUCTION)) {
      return;
    }
    _browserRum.datadogRum.init({
      applicationId: APPLICATION_ID,
      clientToken: CLIENT_TOKEN,
      compressIntakeRequests: true,
      env: environment,
      service: SERVICE,
      site: SITE,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input'
    });
    _browserRum.datadogRum.startSessionReplayRecording();
  }
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});