define("egift-frontend/templates/merchant/confirm-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZCQKAd+G",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"l-page-step-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-step-number\"],[12],[1,[30,[36,0],[\"link.pageTwo\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"hero-text hero-text--with-padding\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"confirmLink.heroText\"],null]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hero-text__secondary\"],[12],[1,[30,[36,0],[\"confirmLink.heroSecondary\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"narrow-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"large-label\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,0,[\"model\",\"linkedPhoneNumber\"]]],[[\"country\"],[[32,0,[\"model\",\"country\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"link-to\",[[24,0,\"button button--primary button--fill t-confirm-done\"]],[[\"@route\",\"@model\"],[\"merchant.view\",[32,0,[\"model\",\"recipientSecret\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"confirmLink.seeBalanceButton\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"format-phone\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/merchant/confirm-link.hbs"
    }
  });
  _exports.default = _default;
});