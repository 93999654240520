define("egift-frontend/templates/merchant/print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ryPBaDU+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container print__container\"],[12],[2,\"\\n  \"],[8,\"print-egift\",[],[[\"@eGiftThemeUrl\",\"@balance\",\"@gan\"],[[32,0,[\"model\",\"egiftDetails\",\"egiftTheme\",\"imageUrl\"]],[32,0,[\"model\",\"giftCard\",\"balance\"]],[32,0,[\"model\",\"giftCard\",\"pan\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "egift-frontend/templates/merchant/print.hbs"
    }
  });
  _exports.default = _default;
});