define("egift-frontend/templates/components/banner-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xpIOlvvP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"banner-message \",[30,[36,0],[[32,0,[\"active\"]],\"banner-message--active\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"banner-message__content\"],[12],[2,\"\\n    \"],[1,[32,0,[\"banner\",\"message\"]]],[2,\"\\n    \"],[11,\"div\"],[24,0,\"banner-message__dismiss\"],[4,[38,1],[\"click\",[32,0,[\"dismiss\"]]],null],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"close\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"inline-svg\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/banner-message.hbs"
    }
  });
  _exports.default = _default;
});